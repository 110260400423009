.gift-option{
	width: 150px;
	height: 150px;
	margin: 10px auto;
	display: block;
	background-position: center;
	background-size: cover;


}

.magalu {
	border-radius: 1000px;

	// @media (min-width: 700px) { 
	// 	margin-right: 50px;
	// }
}

.gift-label{
	max-width: 300px;
}

.flex-center{
	display: flex;
	align-items: center;
}

.col{

	margin: auto 25px;

	@media (max-width: 700px) {
		margin: auto 0 30px 0;
	}
}