@import './../../assets/styles/colors.scss';

.fake-background{
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image : url('./../../assets/img/paper.png');
}

.loading-wrapper{
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .inner{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .wedding-wrapper{
        width: 15vh;
    }

    .welcome{
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        max-width: 70vw;

        .welcome-image-wrapper{

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 15px;

            .welcome-image{
                width: 45px;
                height: 45px;
                border-radius: 100px;
                border: 4px solid white;
                background-color: white;

                &:not(:first-child){
                    margin-left: -15px;
                }
            }
        }

        .family{
            font-weight: 500;
            color: $mainColor;
        }

        span{
            display: block;
        }
    }
}

.btn{
    width: 200px;
    margin-top: 20px;
    background-color: #e6e6e6;
    padding: 20px;
    cursor: pointer;
}

a{
    text-decoration: none;
    color: inherit;
}