@import './colors.scss';

@font-face {
	font-family: "Amsterdam";
	src: local("Amsterdam"), url("./../../fonts/AmsterdamOne.ttf") format("woff");
}

html, body {
	width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image : url('./../../assets/img/paper.png');
	overflow-x: hidden !important;

	&:after{
		content: '';
		width: '100%';
		height: '100%';
		position: absolute;
		top: 0;
		left: 0;
		background-color: red;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.cormorant {
	font-family: "Cormorant Garamond", serif;
	font-weight: lighter;
}

h3.cormorant{
	@media (max-width: 700px) { 
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}
}

.amsterdam {
	font-family: Amsterdam;
	font-weight: lighter;
}

.center {
	text-align: center;
}

.main-color {
	color: $mainColor;
}

footer{
	margin-top: 150px;
	background-color: $mainColor;
	width: 100vw;
	height: 200px;
}