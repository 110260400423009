.countdown-title{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 20px;

    @media (max-width: 640px) { 
        margin-top: 25px;
        font-size: 16px;
	}
}

.countdown-wrapper {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 50vw;
    margin-bottom: 200px;
    justify-content: center;

    .flap{
        margin: 0 10px 0 10px;
    }

    @media (max-width: 640px) { 
        flex-direction: column;

        span{
            margin-bottom: 15px;
        }
	}
}

.michele{
    width: 40%;

    @media (max-width: 640px) { 
        width: 80%;
	}
}

.michele-wrapper{
    @media (max-width: 640px) { 
        text-align: center !important;

        p {
            max-width: 80%;
            margin: 10px auto;
        }

        a{
            margin: 20px auto;
        }
	}
}